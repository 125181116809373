// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { hanleResponseErrors } from '@helpers/Actions';
import { ls } from '@helpers/LocalStorageEnc';
import baseApi from '@src/API/BaseApi/index';

export const handleCompanyLogin = createAsyncThunk(
  'company/login',
  async ({ companyId }, { getState, fulfillWithValue, rejectWithValue }) => {
    const response = await baseApi
      .post('/api/company/login', { company_id: companyId })
      .then((res) => res)
      .catch((e) => hanleResponseErrors(e));
    return response.status === 200 ? fulfillWithValue(response.data) : rejectWithValue(response.data);
  }
);

//add company to temporary state
export const companySlice = createSlice({
  name: 'company',
  initialState: {
    company: {}
  },
  reducers: {
    resetCompanyData: (state, action) => {
      state.company = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(handleCompanyLogin.fulfilled, (state, action) => {
        const company = action?.payload?.company || {};
        const domain = company?.domain;
        localStorage.setItem('domain', domain);
        localStorage.setItem('companyLogo', company?.logo);
        //update company state
        state.company= company;
        ls.setItem('company', JSON.stringify(company));
      })
      .addCase(handleCompanyLogin.rejected, (state, action) => {
        localStorage.removeItem('domain');
        localStorage.removeItem('companyLogo');
        ls.removeItem('company');
      });
  }
});

export const { resetCompanyData } = companySlice.actions;
export default companySlice.reducer;
