// ** React Imports
import { Fragment } from 'react';
import Parser from 'react-html-parser';
import { toast } from 'react-toastify';
import { Bell } from 'react-feather';
// ** Custom Components
import Avatar from '@components/avatar';
// ** Third Party Components
import 'react-toastify/dist/ReactToastify.css';

const Toastify = ({ options }) => {

  const color = options.type == 'error' ? 'danger' : options.type ? options.type : 'info';
  
  return (
    <Fragment>
      <div className="toastify-header">
        <div className="title-wrapper">
          <Avatar size="sm" color={color} icon={<Bell size={12} />} />
          {options.title && <h6 className="toast-title">{options.title}</h6>}
        </div>
        {options.smallDesc && <small className="text-muted">{options.smallDesc}</small>}
      </div>
      {options.text && (
        <div className="toastify-body d-flex w-100">
          <span aria-label={`toast-text`}>{Parser(options.text)}</span>
        </div>
      )}
    </Fragment>
  );
};

export const domNotify = (
  options = {
    show: true,
    type: 'info',
    toastId: 1,
    title: undefined,
    smallDesc: undefined,
    text: undefined,
    additional: undefined,
    callbacks: undefined,
    replaced: false
  },
  toastOptions = {}
) => {
  const TOAST_OPTIONS = {
    icon: false,
    autoClose: 1300,
    hideProgressBar: false,
    toastId: 'only-once',
    onClose: () => {
      if (Array.isArray(options?.callbacks) && options.callbacks?.length) {
        const callbacks = options.callbacks;
        callbacks.forEach((cb) =>  (cb instanceof Function) && cb());
      }
    },
    ...toastOptions
  };

  const notification = (type) => {
    switch (type) {
      case 'success':
        toast.success(<Toastify options={options} />, TOAST_OPTIONS);
        return;
      case 'error':
        //TOAST_OPTIONS.autoClose = 3000;
        toast.error(<Toastify options={options} />, TOAST_OPTIONS);
        return;
      case 'info':
        toast.info(<Toastify options={options} />, TOAST_OPTIONS);
        return;
      case 'warning':
        toast.warning(<Toastify options={options} />, TOAST_OPTIONS);
        return;
      default:
        toast(<Toastify options={options} />, TOAST_OPTIONS);
        return;
    }
  };
  if (options.replaced) {
    toast.dismiss();
    return setTimeout(() => <>{notification(options.type)}</>, 100);
  }
  return <>{notification(options.type)}</>;
};

export const formikErrorToast = (message, options = {}, toastOptions = {}) => {
  const _options = {
    type: 'error',
    title: 'Error',
    text: message || 'Error, Please Fill All Required Fields Correctly!',
    ...options
  };
  domNotify(_options, toastOptions);
};
