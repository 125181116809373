import axios from 'axios';
import { AUTH_ROUTES, getBaseUrl, getUserToken } from '../helpers';
import { ls } from '@src/Services/Helpers/LocalStorageEnc';

let baseApi = axios.create({
  //withCredentials: true
});

const onRequest = (config) => {
    const token = getUserToken();
    config.baseURL = getBaseUrl();
    if (token && typeof token === 'string') {
      config.headers.authorization = `Bearer ${token}`;
      //config.headers.acceptLanguage = `en`;
    }
    return config;
};

const onRequestError = (error) => {
  return Promise.reject(error);
};

const onResponse = (response) => {
  return response;
};

const onResponseError = async (err) => {
  /* const originalConfig = err.config;
  if (!AUTH_ROUTES.includes(originalConfig?.url) && err.response) {
  if (err.response.status === 401 && !originalConfig._retry) {
    originalConfig._retry = true;
    try {
          const rs = await baseApi.post("/api/user/auth/refresh-token", {
            refreshToken: getRefreshToken(),
          });

          const { accessToken } = rs.data;
          //TokenService.updateLocalAccessToken(accessToken);
          return baseApi(originalConfig);

        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }  */
    return Promise.reject(err);
};

baseApi.interceptors.request.use(
  (req) => onRequest(req), 
  (err) => onRequestError(err)
);

baseApi.interceptors.response.use(
  (resp) => onResponse(resp),
  (err) => onResponseError(err)
);

export default baseApi;