import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as moment from 'moment';
import baseApi from '@src/API/BaseApi/index';
import { filterBuilder } from '@helpers/Actions';
import { END_OF_CALENDAR, START_OF_CALENDAR } from '../Calendar/constants';
export const fetchEvents = createAsyncThunk('fetch-work-calender', async (filters, {getState}) => {
  const {start_date, end_date } = filters;
  const queryStr = filterBuilder({
    from: start_date ?? moment().startOf('month').format('YYYY-MM-DD'),
    to: end_date ?? moment().endOf('month').format('YYYY-MM-DD')
  });

  const response = await baseApi.get(`/api/work-calender?${queryStr}`);

  return {
    data : response?.data,
    params: {
      start_date: start_date,
      end_date: end_date
    }
  };
});


//TODO: get all calendar configs (filters, options, weekends and limitations)
export const updateFilter = createAsyncThunk(
  'work-calender',
  async (id, { getState }) => {
    let filters = getState().workCalendar.checkedFilters;
    if (filters.includes(id)) {
      filters = filters.filter(i => i !== id);
    } else {
      filters = [...filters, id];
    }
    return filters;
}
);

const dataMapper = (data) => {

  return data?.map(item => {
    return {
      ...item,
      start: moment(item.date).toDate(),
      end: moment(item.date).toDate(),
      start_time: "08:00",
      end_time: "20:00",
    }
  })
}


export const workCalendarSlice = createSlice({
  name: 'workCalendar',
  initialState: {
    params:{
        start_date: undefined,
        end_date: undefined
    },
    allEvents:[],
    sideBar:[],
    filters: [],
    options: [],
    selectedEvent: {},
    checkedFilters: [],
    allfilters: [],
    weekends: [1, 5],
    officialVacations: [],
    refetchConfig: true,
    isOpen: false,
    datesRangeType: 'all-time',
    status: '',
    validRange: {
      start: START_OF_CALENDAR,
      end: END_OF_CALENDAR
    }
  },
  reducers: {
    selectEvent: (state, action) => {
      const { event } = action.payload;
      state.selectedEvent = event;
    },
    updateStatus: (state, action) => {
      const status = action.payload;
      let events = state.allEvents;
      if (status) {
        events = events.filter(ev => (status === ev.status));
      }
      events = events.filter(ev => (state.checkedFilters?.includes(ev.timesheet_sc_id)));
      //update state
      state.logs = events;
      state.maintenances = events;
      state.status = status;
    },
    updateValidRange: (state, action) => {
      const {start, end, datesRangeType} = action.payload;
      state.validRange = {
        start,
        end
      };
      state.datesRangeType = datesRangeType
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchEvents.fulfilled, (state, action) => {
        const { data, params } = action.payload;
        //handle filtered events
        let events = dataMapper(data);
        state.allEvents = events;
        state.params = params;
      }).addCase(fetchEvents.rejected, (state, action) => {
        console.log("rejected", action);
      })
  }
});

export const { selectEvent, handleAddSidebar, updateStatus, updateValidRange } = workCalendarSlice.actions;

export default workCalendarSlice.reducer;
