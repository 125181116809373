// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { hanleResponseErrors } from '@helpers/Actions';
import baseApi from '@src/API/BaseApi/index';
import { ls } from '@helpers/LocalStorageEnc';
const USER_ABILITY = 'manage';
import crudApi from '@crudApi';
import { getBaseUrl } from '@src/API/helpers';

export const handleRegisterUser = createAsyncThunk(
  'registerUser/register',
  async (values, { getState, fulfillWithValue, rejectWithValue }) => {
    const register = getState()?.user?.register || false;
    if (register) {
      return fulfillWithValue();
    }
    const payload = {
      ...values,
      useFormData: true
    };
    const response = await crudApi.post(
      '/api/user/register',
      payload,
      {
        baseURL: getBaseUrl()
      }
      ).then((res) => res).catch((e) => hanleResponseErrors(e));
    return (response?.status === 200) ? fulfillWithValue({ data: response.data, user: values }) : rejectWithValue(response.data);
  }
);


//add User to temporary state
export const saveCriteriaInfo = createAsyncThunk(
  'registerUser/saveCriteriaInfo',
  async (params) => params
);

export const saveUserInfo = createAsyncThunk('registerUser/saveUserInfo', async (params) => params);

export const resendMail = createAsyncThunk(
  'helpers/resendMail',
  async (_, { fulfillWithValue, rejectWithValue }) => {
      const response = await baseApi.post('/api/user/resend-verification-code')
        .then((res) => res)
        .catch((e) => hanleResponseErrors(e));
        return (response?.status === 200) ? fulfillWithValue(response.data) : rejectWithValue({});
  }
);

export const changeEmail = createAsyncThunk(
  'helpers/changeEmail',
  async ({ email }, { fulfillWithValue, rejectWithValue }) => {
    const response = await baseApi.post('/api/user/change-email', { email: email })
      .then((res) => res)
      .catch((e) => hanleResponseErrors(e));
    return response?.status === 200 ? fulfillWithValue({
      data: response.data,
      email: email
    }) : rejectWithValue(response.data);
  }
);


export const verifyMailAndPhone = createAsyncThunk(
  'helpers/verifyMailAndPhone',
  async (data, { fulfillWithValue, rejectWithValue }) => {
  
    const response = await baseApi.post('/api/user/check-verification-code', {
          ...data
      })
      .then((res) => res)
      .catch((e) => hanleResponseErrors(e));
    return response?.status === 200
      ? fulfillWithValue(response.data)
      : rejectWithValue(response.data);
  }
);

//
const initialState = {
  user: {},
  register: false,
  verification: {
    codes: {
      mobile: undefined,
      email: undefined
    },
    declarations: {
      user: undefined
    }
  }
};

export const registerUserSlice = createSlice({
  name: 'registerUser',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(saveCriteriaInfo.fulfilled, (state, action) => {
        state.user = { ...state.user, ...action.payload };
      })
      .addCase(saveCriteriaInfo.rejected, (state, action) => {
        //hendle criteria saving error
      
      })
      .addCase(saveUserInfo.fulfilled, (state, action) => {
        state.user = { ...state.user, ...action.payload };
      })
      .addCase(saveUserInfo.rejected, (state, action) => {
      
      })
      .addCase(resendMail.fulfilled, (state, action) => {
      
      })
      .addCase(resendMail.rejected, (state, action) => {
      
      })
      .addCase(changeEmail.fulfilled, (state, action) => {
        //console.log('changeEmail-fulfilled', action.payload);
        const email = action.payload.email;
        state.user = {...state.user, email: email};
      })
      .addCase(changeEmail.rejected, (state, action) => {
      
      })
      .addCase(handleRegisterUser.rejected, (state, action) => {
        state.user = {};
        ls.removeItem('token');
        ls.removeItem('user');
        localStorage.removeItem('token');
      })
      .addCase(handleRegisterUser.fulfilled, (state, action) => {
        const { token = undefined, ...restUser } = action?.payload?.data || {};

        if (token && token?.length > 0 && Object.keys(restUser)) {
          state.register = true;
          state.user = restUser;
          //
          ls.setItem('token', token);
          ls.setItem('user', restUser);
          localStorage.setItem('userData', JSON.stringify({ ability: USER_ABILITY }));
        }
      }).addCase(verifyMailAndPhone.fulfilled, (state, action) => {
        state.user = {};
        state.register = false;
        ls.clear();
        localStorage.clear();
      })
      .addCase(verifyMailAndPhone.rejected, (state, action) => {
        state.user = {};
        state.register = false;
        ls.clear();
        localStorage.clear();
      });
  }
});

export default registerUserSlice.reducer;
