// You can customize the template with the help of this file

//Template config options
const themeConfig = {
  app: {
    appName: undefined, //IGS APP
    appLogoImage: require('@src/assets/images/logo/companies-logo.png').default,
    appLogoImageCollapse: require('@src/assets/images/logo/companies-logo.png').default///static/media/companies-logo.4c576241.png
  },
  layout: {
    isRTL: false,
    skin: 'semi-dark', // light, dark, bordered, semi-dark
    routerTransition: 'fadeInLeft', // bounceOutLeft fadeIn, fadeInLeft, zoomIn, none or check this for more transition https://animate.style/
    type: 'vertical', // vertical, horizontal
    contentWidth: 'full', // full, boxed
    menu: {
      isHidden: false,
      isCollapsed: false
    },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: 'floating', // static , sticky , floating, hidden
      backgroundColor: 'white' // BS color options [primary, success, etc]
    },
    footer: {
      type: 'static' // static, sticky, hidden
    },
    customizer: true,
    scrollTop: true, // Enable scroll to top button
    logoBackgroundColor: 'rgba(40, 48, 70, 1)' // Set the background color of the logo in navbar.
  }
};

export default themeConfig;
