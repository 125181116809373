import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { get, values } from 'lodash';

export const getAllTabs = createAsyncThunk(
  'dynamicTabs/getAll',
  async (data = { key: '' }, { getState }) => {
    let state = getState().dynamicTabs;
    return state?.tabs[data.key] ? [...state.tabs[data.key]] : [];
  }
);

export const saveTab = createAsyncThunk('dynamicTabs/save', async (data, { getState }) => {
  let newTabs = {};
  const { key, tabId, item } = data;
  item.tabId = tabId;
  //
  const allTabs = getState().dynamicTabs.tabs;
  let oldTabs = get(allTabs, key) ? get(allTabs, key) : {};
  //
  if (Object.keys(oldTabs).length > 0) {
    newTabs[key] = {
      ...oldTabs,
      [tabId]: item
    };
  } else {
    newTabs[key] = {
      [tabId]: item
    };
  }
  //console.log("updatedSave",{...allTabs, ...newTabs})
  return { ...allTabs, ...newTabs };
});

export const removeTab = createAsyncThunk('dynamicTabs/remove', async (data, { getState }) => {
  let newTabs = {};
  const { key, tabId } = data;
  const allTabs = getState().dynamicTabs.tabs;
  let oldTabs = get(allTabs, key) ? values(get(allTabs, key)) : {};
  //if (oldTabs.length > 0) {
  newTabs[key] = oldTabs.filter((e) => e.tabId != tabId);
  //}
  //console.log("updatedRemove",{...allTabs, ...newTabs})
  return { ...allTabs, ...newTabs };
});

export const restTab = createAsyncThunk('dynamicTabs/resetTab', async (data, { getState }) => {
  let newTabs = {};
  const { key, tabId, item } = data;
  item.tabId = tabId;
  //
  const allTabs = getState().dynamicTabs.tabs;
  let oldTabs = get(allTabs, key) ? get(allTabs, key) : {};
  //
  if (Object.keys(oldTabs).length > 0) {
    newTabs[key] = {
      ...oldTabs,
      [tabId]: item
    };
  } else {
    newTabs[key] = {
      [tabId]: item
    };
  }
  return { ...allTabs, ...newTabs };
});

export const clearTabs = createAsyncThunk('dynamicTabs/clear', async (data, { getState }) => {
  let newTabs = {};
  newTabs[data.key] = {};
  return { ...allTabs, ...newTabs };
});

export const dynamicTabsSlice = createSlice({
  name: 'dynamicTabs',
  initialState: {
    tabs: {}
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllTabs.fulfilled, (state, action) => {
        //state.tabs = action.payload
      })
      .addCase(saveTab.fulfilled, (state, action) => {
        state.tabs = action.payload;
      })
      .addCase(removeTab.fulfilled, (state, action) => {
        state.tabs = action.payload;
      })
      .addCase(clearTabs.fulfilled, (state, action) => {
        state.tabs = action.payload;
      })
      .addCase(restTab.fulfilled, (state, action) => {
        state.tabs = action.payload;
      });
  }
});

export default dynamicTabsSlice.reducer;
