// ** React Imports
import { Fragment } from 'react';

// ** Custom Components
import Avatar from '@components/avatar';
import { useDispatch, useSelector } from 'react-redux';

// ** Third Party Components
import { resetToast } from '@store/actions/Notify';
import { toast } from 'react-toastify';
import { Bell } from 'react-feather';
import 'react-toastify/dist/ReactToastify.css';

const Toastify = ({ options }) => {
  const color = options.type == 'error' ? 'danger' : options.type;
  return (
    <Fragment>
      <div className="toastify-header">
        <div className="title-wrapper">
          <Avatar size="sm" color={color} icon={<Bell size={12} />} />
          <h6 className="toast-title">{options.title}</h6>
        </div>
        <small className="text-muted">{options.smallDesc}</small>
      </div>
      <div className="toastify-body">
        <span aria-label={`toast-text`}>{options.text}</span>
      </div>
    </Fragment>
  );
};

const Toaster = () => {
  const dispatch = useDispatch();
  //
  const toastify = useSelector((state) => state?.toastify ?? {});
  const callbacks = toastify?.callbacks ? toastify?.callbacks : [];

  const TOAST_OPTIONS = {
    icon: false,
    autoClose: 1300,
    hideProgressBar: false,
    onClose: () => {
      if (callbacks.length) {
        callbacks.forEach((cb) => typeof cb == 'function' && cb());
      }
      dispatch(resetToast());
    }
  };

  const notification = (type) => {
    switch (type) {
      case 'success':
        toast.success(<Toastify options={toastify} />, TOAST_OPTIONS);
        return;
      case 'error':
        TOAST_OPTIONS.autoClose = 3000;
        toast.error(<Toastify options={toastify} />, TOAST_OPTIONS);
        return;
      case 'info':
        toast.info(<Toastify options={toastify} />, TOAST_OPTIONS);
        return;
      case 'warning':
        toast.warning(<Toastify options={toastify} />, TOAST_OPTIONS);
        return;
      default:
        toast(<Toastify options={toastify} />, TOAST_OPTIONS);
        return;
    }
  };
  return <>{toastify?.show && notification(toastify?.type)} </>;
};

export default Toaster;
