// ** React Imports
import { useEffect, useState } from 'react';
// ** Reactstrap Imports
import { Button, ButtonGroup, Row } from 'reactstrap';
import moment from 'moment';
import MonthsPicker from './ReactDatePicker/MonthsPicker';
import QuarterPicker from './ReactDatePicker/QuarterPickers';
import YearsPicker from './ReactDatePicker/YearsPicker';
import RangePicker from './ReactDatePicker/RangePicker';
import DayPicker from './ReactDatePicker/DayPicker';

const PeriodicSelector = ({ 
  fullWidth = false,
  autoApply = false,
  defaultSelect= 'all-time',
  allowedPeriods=['day', 'month', 'quarter', 'year', 'range' ],
  defaultFrom = undefined,
  defaultTo= undefined,
  handleDateChange,
   ...props 
  }) => {
  // ** States
  const [rSelected, setRSelected] = useState(defaultSelect);
  const [btnGroupVertical, setBtnGroupVertical] = useState(false);
  const [customClasses, setCustomClasses] = useState(fullWidth ? 'w-100' : '');

  const getToday = () => {
    let startDate = moment().startOf('day').format('YYYY-MM-DD'); //start on monday
    let endDate = moment().endOf('day').format('YYYY-MM-DD'); // ends on saturday
    return { startDate, endDate };
  };

  const getWeekDays = () => {
    let startDate = moment().startOf('isoweek').format('YYYY-MM-DD'); //start on monday
    let endDate = moment().endOf('isoweek').format('YYYY-MM-DD'); // ends on saturday
    return { startDate, endDate };
  };

  const getQuarterDays = (quarter = moment().quarter()) => {
    const startDate = moment().quarter(quarter).startOf('quarter').format('YYYY-MM-DD');
    const endDate = moment().quarter(quarter).endOf('quarter').format('YYYY-MM-DD');
    return { startDate, endDate };
  };

  const getMonthDays = (month = moment().month()) => {
    const startDate = moment().month(month).startOf('month').format('YYYY-MM-DD');
    const endDate = moment().month(month).endOf('month').format('YYYY-MM-DD');
    return { startDate, endDate };
  };

  const getYearDays = (year = moment().year()) => {
    const startDate = moment().year(year).startOf('year').format('YYYY-MM-DD');
    const endDate = moment().year(year).endOf('year').format('YYYY-MM-DD');
    return { startDate, endDate };
  };

  const getDatesData = (selected) => {
    let data = {
      message: `${moment().format('MMMM')}, ${moment().year()}`,
      dateFrom: moment().startOf('month').format('YYYY-MM-DD'),
      dateTo: moment().endOf('month').format('YYYY-MM-DD')
    };
    let days = {};
    switch (selected) {
      case 'day':
        days = getToday();
        data.message = `${moment().format('LL')}`;
        //data.message = `${moment().format("DD,MMMM"), moment().year()}`;
        data.dateFrom = moment().startOf('day').format('YYYY-MM-DD');
        data.dateTo = moment().endOf('day').format('YYYY-MM-DD');
        break;
      case 'week':
        days = getWeekDays();
        data.message = `Week ${moment().week()}, ${moment().year()}`;
        data.dateFrom = moment().startOf('week').format('YYYY-MM-DD');
        data.dateTo = moment().endOf('week').format('YYYY-MM-DD');
        break;
      case 'month':
        days = getMonthDays();
        data.message = `${moment().format('MMMM')}, ${moment().year()}`;
        data.dateFrom = moment().startOf('month').format('YYYY-MM-DD');
        data.dateTo = moment().endOf('month').format('YYYY-MM-DD');
        break;
      case 'quarter':
        days = getQuarterDays();
        data.message = `${moment().format('Qo')} Quarter, ${moment().year()}`;
        data.dateFrom = moment().startOf('quarter').format('YYYY-MM-DD');
        data.dateTo = moment().endOf('quarter').format('YYYY-MM-DD');
        break;
      case 'year':
        days = getYearDays();
        data.message = `Year ${moment().format('YYYY')}`;
        data.dateFrom = moment().startOf('year').format('YYYY-MM-DD');
        data.dateTo = moment().endOf('year').format('YYYY-MM-DD');
        break;
      case 'range':
        data.message = 'Custom Date';
        data.dateFrom = moment().startOf('day').format('YYYY-MM-DD');
        data.dateTo = moment().endOf('day').format('YYYY-MM-DD');
        break;
      default:
      case 'all-time':
        data.dateFrom = defaultFrom;
        data.dateTo = defaultTo;
        break;
    }
    return data;
  };

  const periodeHandler = (selected) => {
    let { dateFrom, dateTo } = getDatesData(selected);
    setRSelected(selected);
    if (selected) {
      handleDateChange([dateFrom, dateTo], selected);
    }
  };

  const resizeWindow = () => {
    setBtnGroupVertical(window.innerWidth < 300);
    setCustomClasses(fullWidth ? 'w-100' : window.innerWidth < 300 ? 'w-100' : '');
  };

  useEffect(() => {
    resizeWindow();
    window.addEventListener('resize', resizeWindow);
    return () => window.removeEventListener('resize', resizeWindow);
  }, []);

  return (
    <>
      <Row className="w-100 align-items-center justify-content-center mx-auto fs-5 px-0">
        <div className="d-flex justify-content-center no-grow w-100">
          <ButtonGroup
            className={`${(rSelected === defaultSelect) ? '' : 'mb-1'} ${customClasses}`}
            style={{ minWidth: '70%' }}
            vertical={btnGroupVertical}>
            <Button
              color="primary"
              onClick={() => periodeHandler(defaultSelect)}
              active={rSelected === defaultSelect}
              outline>
              All Time
            </Button>
            {allowedPeriods?.includes('day') && (<Button
              color="primary"
              onClick={() => periodeHandler('day')}
              active={rSelected === 'day'}
              outline>
              Day
            </Button>)}
            {allowedPeriods?.includes('month') && (<Button
              color="primary"
              onClick={() => periodeHandler('month')}
              active={rSelected === 'month'}
              outline>
              Month
            </Button>)}
            {allowedPeriods?.includes('quarter') && (<Button
              color="primary"
              onClick={() => periodeHandler('quarter')}
              active={rSelected === 'quarter'}
              outline>
              Quarter
            </Button>)}
            {allowedPeriods?.includes('year') && (<Button
              color="primary"
              onClick={() => periodeHandler('year')}
              active={rSelected === 'year'}
              outline>
              Year
            </Button>)}
            {allowedPeriods?.includes('range') && (<Button
              color="primary"
              onClick={() => periodeHandler('range')}
              active={rSelected === 'range'}
              outline>
              Range
            </Button>)}
          </ButtonGroup>
        </div>
      </Row>
      <div className="custom-flex-row w-100 align-items-center justify-content-center mx-auto fs-5">
        <div className="flex-2-col no-grow" style={{ width: 'auto' }}>
          {rSelected && rSelected === 'day' && (
            <DayPicker
              name="day"
              id="day"
              className="text-center fs-3"
              maxDate={moment().toDate()}
              handleDateChange={(date) => handleDateChange(date, 'day')}
              autoApply={autoApply}
            />
          )}
          {rSelected && rSelected === 'month' && (
            <MonthsPicker
              name="month"
              id="month"
              className="text-center fs-3"
              maxDate={moment().toDate()}
              handleDateChange={(date) => handleDateChange(date, 'month')}
              autoApply={autoApply}
            />
          )}

          {rSelected && rSelected === 'quarter' && (
            <QuarterPicker
              name="quarter"
              id="quarter"
              className="text-center fs-3"
              maxDate={moment().toDate()}
              handleDateChange={(date) => handleDateChange(date, 'quarter')}
              autoApply={autoApply}
            />
          )}

          {rSelected && rSelected === 'year' && (
            <YearsPicker
              name="year"
              id="year"
              className="text-center fs-3"
              maxDate={moment().toDate()}
              handleDateChange={(date) => handleDateChange(date, 'year')}
              autoApply={autoApply}
            />
          )}

          {rSelected && rSelected === 'range' && (
            <RangePicker
              name="range"
              id="range"
              className="text-center fs-3"
              maxDate={moment().toDate()}
              handleDateChange={(date) => handleDateChange(date, 'range')}
              autoApply={autoApply}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default PeriodicSelector;
