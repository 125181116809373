import { domNotify } from '@src/@core/components/Notify/DomNotify';
import { logout } from '@src/Services/Helpers/Actions';
import { QueryClient } from '@tanstack/react-query';

export const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: 1,
        retryDelay: 300,
        keepPreviousData: true,
        staleTime: 1000 * 30, // 30seconds
        cacheTime: 1000 * 30, //30 seconds
        //refetchInterval: 1000 * 5, //30 seconds
        refetchIntervalInBackground: false,
        refetchOnReconnect: true,
        refetchOnWindowFocus: false,
        suspense: false,
        onError: (err) => {
          if (err?.response?.status === 401) {
            domNotify({
              type: "warning",
              text: "Session Expired",
              smallDesc: "Session Expired: Please log in again to refresh your session.",
              callbacks: [() => logout()]
            })
          }
        }
      },
      mutations: {
        retry: 0,
        retryDelay: 300,
        onError: (err) => {
          if (err?.response?.status === 401) {
            logout();
          }
        }
      },

    }
  });