// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { hanleResponseErrors } from '@helpers/Actions';
import baseApi from '@src/API/BaseApi/index';

export const handleforgetCompanyId = createAsyncThunk(
  'forgetCompany/forgetId',
  async ({ email }, { getState, fulfillWithValue, rejectWithValue }) => {
    const response = await baseApi
      .post('/api/company/forget-id', { admin_email:email })
      .then((res) => res)
      .catch((e) => hanleResponseErrors(e));
    return response?.status === 200
      ? fulfillWithValue(response.data)
      : rejectWithValue(response.data);
  }
);

//
export const forgetCompanySlice = createSlice({
  name: 'forgetCompany',
  initialState: {},
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(handleforgetCompanyId.fulfilled, (state, action) => {
        //const dispatch = action.meta
        //TODO: move notification into
      })
      .addCase(handleforgetCompanyId.rejected, (state, action) => {
      
      });
  }
});

export default forgetCompanySlice.reducer;
