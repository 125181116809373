// ** Reducers Imports
import NiceModal from '@ebay/nice-modal-react';
import navbar from './navbar';
import layout from './layout';
import sidebar from './sidebar';

import appUsers from '@dashboard/User/Profile/ViewProfile/store';
import dynamicTabs from '@components/DynamicTabs/store';
//Company reducers
import company from '@companyAuth/Login/store';
import registerCompany from '@companyAuth/Register/store';
import forgetCompany from '@companyAuth/Forget/store';
import salesMeeting from '@companyAuth/ArrangeSalesMeeting/store';

//User Reducers
import forgetUser from '@userAuth/Forget/store';
import loginUser from '@userAuth/Login/store';
import registerUser from '@userAuth/Register/store';
import resetUserPassword from '@userAuth/Reset/store';

import helpers from './actions/Helpers';
import toastify from './actions/Notify';

import modalReducer from '@components/Modals/store';
import AuthSlice from './reducers/auth/AuthSlice';

import systemTypes from '@dashboard/WorkSector/SystemConfigurations/DropdownLists/store';
import systemLists from '@components/ReduxComponents/SingleSelect/store';
import appDescussions from '@dashboard/Chat/store';

//Work Sector
// import companyVacationsCalendar from '@dashboard/WorkSector/SystemConfigurations/CompanyOfficialVacations/store';
import companySettings from '@dashboard/WorkSector/SystemConfigurations/CompanyProfile/store';
import workCalendar from "@workSector/WorkCalendar/store"
import dashboardStatistics from "@personalSector/Dashboard/store"

const nicemodals = NiceModal.reducer;

const rootReducer = {
  authMe: AuthSlice,
  nicemodals,
  toastify,
  helpers,
  navbar,
  layout,
  sidebar,
  forgetCompany,
  company,
  registerCompany,
  salesMeeting,
  forgetUser,
  loginUser,
  registerUser,
  resetUserPassword,
  modalReducer,
  appDescussions,
  appUsers,
  // companyVacationsCalendar,
  //ecommerce,
  systemTypes,
  systemLists,
  dynamicTabs,
  companySettings,
  workCalendar,
  dashboardStatistics
};

export default rootReducer;
