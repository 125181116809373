import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { handleConfirmCancel } from '@helpers/ConfirmAlerts';
import baseApi from '@src/API/BaseApi';
import crudApi from '@src/API/CrudApi';
import { domNotify } from '@domNotify/index';
import { filterBuilder } from '@helpers/Actions';
import { getOptions } from '@components/ReduxComponents/SingleSelect/store';


//TODO: must improved

export const getData = createAsyncThunk('systemTypes/getData', async (params) => {
  const { type } = params;
  const p = filterBuilder(params);
  if (!type) {
    throw new Error('Error Invalid type');
  }
  const response = await baseApi.get(`/api/system-configs/${type}?${p}`);
  return {
    params,
    data: response.data.data.list.data,
    total: response.data.data.list.total
  };
});

export const addSystemType = createAsyncThunk(
  'systemTypes/addSystemType',
  async (params, { dispatch, getState, fulfillWithValue, rejectWithValue }) => {
    let data = [];
    const { type, items } = params;
    items.map((item) => {
      item.status = true;
      data.push(item);
    }); 
    const response = await baseApi.post(`/api/system-configs/${type}`, {
      items: data
    })
    .then(res => res)
    .catch(err => err);
    //fetch data
    await dispatch(getData(getState().systemTypes.params));
    //refetch options
    await dispatch(getOptions({type: type, searchParams: {}}));
    //
    return (response?.status === 200 ? fulfillWithValue({
      data: response
    }) : rejectWithValue({
      data: response
    }));
  }
);

export const updateSystemType = createAsyncThunk(
  'systemTypes/editSystemType',
  async (params, { dispatch, getState, fulfillWithValue, rejectWithValue }) => {
    const response = await baseApi.put(`/api/system-configs/${params?.type}/${params?.id}`, {
      ...params,
    })
    .then(res => res)
    .catch(err => err);

    dispatch(getData(getState().systemTypes.params));
    dispatch(getOptions({type: params?.type, searchParams: {}}));
    return (response?.status === 200 ? fulfillWithValue({
      data: response
    }) : rejectWithValue({
      data: response
    }));
  }
);

export const updateStatus = createAsyncThunk(
  'systemTypes/editSystemType',
  async (params, { dispatch, getState, fulfillWithValue, rejectWithValue }) => {
    const { type, id, status } = params;
    const response = await baseApi.put(`/api/system-configs/${type}/${id}`, {
      status: status ,
      id ,
    })
    .then(res => res)
    .catch(e => e);
    dispatch(getData(getState().systemTypes.params));
    dispatch(getOptions({type: params?.type, searchParams: {}}));
    return (response?.status === 200 ? fulfillWithValue({
      data: response
    }) : rejectWithValue({
      data: response
    }));
  }
);

export const deleteSystemType = createAsyncThunk(
  'systemTypes/deleteSystemType',
  async (params, { dispatch, getState }) => {
    const { type, id } = params;
    await baseApi.delete(`/api/system-configs/${type}/${id}`);
    await dispatch(getData(getState().systemTypes.params));
    await dispatch(getOptions({type: type, searchParams: {}}));
    return id;
  }
);

export const handleDelete = createAsyncThunk(
  'systemTypes/handleDelete',
  async (params, { dispatch }) => {
    handleConfirmCancel('Delete', 'This item will be deleted, kindly confim?', false, () => {
      dispatch(deleteSystemType(params));
    });
  }
);

export const submitFile = createAsyncThunk(
  'systemTypes/submitFile',
  async (params, { dispatch, getState }) => {
    const { type, file } = params;
    const payload = {
      type: type,
      file: file,
      useFormData:true,
    };
    await crudApi.post(`/api/system-configs/${type}/import`, payload);
    await dispatch(getData(getState().systemTypes.params));
    return 'success';
  }
);

export const systemTypesSlice = createSlice({
  name: 'systemTypes',
  initialState: {
    isReady: false,
    data: [],
    total: 1,
    params: {},
    allData: [],
    systemType: {}
  },
  reducers: {
    resetSystemType: (state, action) => {
      state = {
        isReady: false,
        data: [],
        total: 1,
        params: {},
        allData: [],
        systemType: {}
      };
      return state;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getData.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.params = action.payload.params;
      state.total = action.payload.total;
    }).addCase(getData.rejected, (state, action) => {
      state.data = [];
      state.total = 1;
    }).
    /* addCase(deleteSystemType.fulfilled, (state, action) => {
      domNotify({
        type: 'success',
        title: 'Delete Successful',
        text: 'Record Deleted Successfully'
      })
    }).addCase(deleteSystemType.rejected, (state, action) => {
      domNotify({
        type: 'error',
        title: 'Delete Error',
        text: 'Error Occurred While Record Deleting'
      })
    }). */
    addCase(updateSystemType.fulfilled, (state, action) => {
      domNotify({
        type: 'success',
        title: 'Update Success',
        text: action?.payload?.data?.data?.message || 'Record Updated Successfully'
      })
    }).addCase(updateSystemType.rejected, (state, action) => {
      domNotify({
        type: 'error',
        title: 'Update Error',
        text: action.payload?.data?.response?.data?.message || 'Error Occurred While Updating'
      })
    }).addCase(addSystemType.fulfilled, (state, action) => {
      domNotify({
        type: 'success',
        title: 'Records Submitting',
        text: action?.payload?.data?.data?.message || 'Records Submitted Successfully',
      });
    }).addCase(addSystemType.rejected, (state, action) => {
      domNotify({
        type: 'error',
        title: 'Records Submitting',
        text: action.payload?.data?.response?.data?.message || 'Records Submitted Successfully',
      });
    })
  }
});
export const { resetSystemType } = systemTypesSlice.actions;
export default systemTypesSlice.reducer;
