import { ls } from "@src/Services/Helpers/LocalStorageEnc";

/* class storeInstance {
  constructor(value){
      this.store = value;
  }

  get store(){ 
    return this._store 
  };
  set store(value){ 
    this._store = value 
  }
} */

export const storeData = {
  store: undefined,
  /* set store(s) {
    this.store = s;
  },
  get store() {
    return this.store;
  } */
}

export const AUTH_ROUTES = [
  "/api/user/auth/login"
];

export const getBaseUrl = () => {
  const domain = localStorage.getItem('domain');
  if (domain) {
    return `${process.env.REACT_APP_BASE_API_PROTOCOL}${domain}`;
  }
  return `${process.env.REACT_APP_BASE_API_PROTOCOL}${process.env.REACT_APP_BASE_API_DOMAIN}`;
};

export const getUserToken = () => ls?.getItem('token') || undefined;
export const getRefreshToken = () => ls?.getItem('refresh_token') || undefined;