// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { hanleResponseErrors } from '@helpers/Actions';
import { ls } from '@helpers/LocalStorageEnc';
import baseApi from '@src/API/BaseApi/index';
import * as moment from 'moment';

const USER_ABILITY = 'read';

export const handleUserLogin = createAsyncThunk(
  'loginUser/login',
  async ({ email, userPassword }, { getState, fulfillWithValue, rejectWithValue }) => {
    const response = await baseApi.post('/api/user/login', { email, password: userPassword })
      .then((res) => res)
      .catch((e) => hanleResponseErrors(e));
    return (response?.status === 200 && response?.data?.access_token) ? (fulfillWithValue(response.data)) : rejectWithValue(response.data);
  }
);


export const handleUserLogout = createAsyncThunk(
  'loginUser/logout',
  async (_, { getState, fulfillWithValue, rejectWithValue }) => {
    const response = await baseApi.post('/api/user/logout')
      .then((res) => res)
      .catch((e) => hanleResponseErrors(e));
    return response?.status === 200
      ? fulfillWithValue(response.data)
      : rejectWithValue(response.data);
  }
);

const handleResponse = (state, action) => {
    const user = action?.payload?.user;
    const token = action?.payload?.access_token;
    state.user = user;
    state.permissions = user?.permissions|| [];
    state.isLogin = true;
    ls.setItem('token', token);
    localStorage.setItem('userData', JSON.stringify({ ability: USER_ABILITY }));
}

export const loginUserSlice = createSlice({
  name: 'loginUser',
  initialState: {
    user: {},
    permissions: [],
    isLogin: false,
  },
  reducers: {
    updateUserInfo: (state, action) => {
      handleResponse(state, action);
    },
    userStateDestroy: (state, action) => {
      state.user = {};
      state.permissions = [];
      state.isLogin = false;
      state.loginAt  = null;
    }
  },
  extraReducers: (builder) => {
    //todo must add data
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(handleUserLogin.fulfilled, (state, action) => {
        state.loginAt  = moment();
        handleResponse(state, action)
      })
      .addCase(handleUserLogin.rejected, (state, action) => {
        state.user = undefined;
        state.isLogin = false;
        state.permissions = [];
        ls.removeItem('token');
        localStorage.removeItem('token');
      })
      .addCase(handleUserLogout.fulfilled, (state, action) => {
        state.user = undefined;
        state.isLogin = false;
        state.permissions = [];
        state.loginAt  = null ;
        ls.clear();
        localStorage.clear();
      })
      .addCase(handleUserLogout.rejected, (state, action) => {
        state.user = undefined;
        state.isLogin = false;
        state.permissions = [];
        state.loginAt  = null ;
        ls.clear();
        localStorage.clear();
      });
  }
});
export const { updateUserInfo, userStateDestroy } = loginUserSlice.actions
export default loginUserSlice.reducer;
