// ** Redux Imports
import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import { expireReducer } from 'redux-persist-expire';
import storage from 'redux-persist/lib/storage';
import storageDB from 'redux-persist-indexeddb-storage';
//import storage from 'redux-persist/lib/storage/session';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import rootReducer from './rootReducer';
//import thunk from 'redux-thunk'

const encryptionKey = 'RPJk9@lotech';

const reducers = combineReducers(rootReducer);
const transforms = [
  encryptTransform({
    secretKey: encryptionKey,
    onError: function (error) {
    
    }
  })
  /*expireReducer('preference', {
      // (Optional) Key to be used for the time relative to which store is to be expired
      persistedAtKey: '__persisted_at',
      // (Required) Seconds after which store will be expired
      expireSeconds: 60,
      // (Optional) State to be used for resetting e.g. provide initial reducer state
      expiredState: {},
      // (Optional) Use it if you don't want to manually set the time in the reducer i.e. at `persistedAtKey` 
      // and want the store to  be automatically expired if the record is not updated in the `expireSeconds` time
      autoExpire: true
    })*/
];

const persistedReducer = persistReducer(
  {
    key: 'root',
    transforms: transforms,
    //storage,
    storage: storageDB('igs-app'),
    //whiltelist needed reducers OR blacklist not needed reduces
    blacklist: ['toastify', 'modalReducer', 'nicemodals'],
    writeFailHandler: () => {
      console.error('an error has occured while writing state');
    }
  },
  reducers
);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: true
    });
  }
});

const persistor = persistStore(store);

export const rootState = store.getState();
//console.log(rootState)
export { store, persistor };
//export const getStore = () => store
//export const getRootState = () => rootState
