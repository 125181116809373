export const links = Object.freeze({
  "countries": "api/list/countries",
  "departments": "/api/list/departments",
  "main-areas" : "/api/list/fire-main-area",
  "sub-areas" : "/api/list/fire-sub-area",
  "company-branches": "api/list/branches",
  "brands": "api/list/brands",
  //
  "roles": "/api/list/roles",
  "users-list": "/api/list/users",
  //
  "company-sectors": "/api/list/company-sectors",
  "copanel-list": "/api/list/control-panel",
  "fire-system-types": "/api/list/fire-system-types",
  "fire-fighting-types": "/api/list/fire-fighting-system-types",
  "control_panel": "/api/list/control-panel",
  "gas_fire_suppression": "/api/list/gas-fire-suppression",
  "water_fighting_system": "/api/list/water-fighting-system",
  "foam_fighting_system": "/api/list/foam-fighting-system",
  "fire_pipeline": "/api/list/fire-pipeline",
  "network_valves": "/api/list/network-valves",
  "fire_hydrant": "/api/list/fire-hydrant",
  "hosereels": "/api/list/hosereels",
  "fire_extinguishers": "/api/list/fire-extinguishers",
  "fire_pumps": "/api/list/fire-pumps",
  "fire_search": "/api/list/fire-search",
  "logs": "/api/list/logs",
  "alarm": "/api/list/alarm-logs",
  "fighting": "/api/list/fighting-logs",
  "panels_faults": "/api/list/panel-fault-logs",
  "mechanical_faults": "/api/list/mechanical-fault-logs",
  "fault-reasons": "/api/list/fault-reasons",
  "log-types": "/api/list/log-types",
  "panel-faults-list": "/api/list/fault-reasons?type=panel",
  "mechanical-faults-list": "/api/list/fault-reasons?type=mechanical",
  "spare-parts-categories": "/api/list/spare-parts-category",
  "suppliers-list": "/api/list/suppliers",
  "spare-parts": "/api/list/spare-parts",
});