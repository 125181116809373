//import { Ability } from '@casl/ability'
//import { initialAbility } from './initialAbility'
//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
//const userData = JSON.parse(localStorage.getItem('userData'))
//const existingAbility = userData ? userData.ability : null
//const existingAbility = "manage"
//export default new Ability(existingAbility || initialAbility)
//export default new Ability(initialAbility)

import { createMongoAbility , AbilityBuilder } from '@casl/ability';
import { store } from '@src/redux/store';

const ability = new createMongoAbility();

store.subscribe(() => {
  let authMe = store.getState().authMe;
  ability.update(defineRulesFor(authMe));
});

const defineRulesFor = (authMe) => {
  const permissions = authMe.permissions;
  const { can, rules } = new AbilityBuilder();
  if (permissions) {
    [...new Set(permissions)].forEach((p) => {
      let per = p.split('_');
      can(per[0], per[1]);
    });
  }
  //console.log(rules)
  return rules;
};

export default ability;
