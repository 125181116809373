import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handleMenuCollapsed } from '@store/sidebar';

export const toggleModal = createAsyncThunk('modal/toggle', async (status, { dispatch }) => {
  await dispatch(handleMenuCollapsed(status));
  //await thunkApi.dispatch(toggleModalStatus(status))
  return status;
});

const modalSlice = createSlice({
  name: 'modalState',
  initialState: {
    isOpen: false,
    content: <></>
  },
  reducers: {
    setModalContent: (state, action) => (state.content = action.content),
    getModalContent: (state, action) => state.content
  },
  extraReducers: (builder) => {
    builder.addCase(toggleModal.fulfilled, (state, action) => {
      state.isOpen = action.payload;
    });
  }
});
const modalReducer = modalSlice.reducer;

export const { setModalContent, getModalContent } = modalSlice.actions;
export default modalReducer;
