// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit';

// ** ThemeConfig Import
import themeConfig from '@configs/themeConfig';

const initialMenuCollapsed = () => {
  let status = false;
  try {
    const item = !!window.localStorage.getItem('menuCollapsed');
    //** Parse stored json or if none return initialValue
    status = item ? JSON.parse(item) : themeConfig.layout.menu.isCollapsed;
  } catch (err) {
    window.localStorage.setItem('menuCollapsed', JSON.stringify(status));
  }
  return status;
};

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState: {
    menuCollapsed: initialMenuCollapsed(),
    defaultCollapsed: initialMenuCollapsed()
  },
  reducers: {
    handleMenuCollapsed: (state, action) => {
      let status = false;
      if (
        typeof action.payload === 'object' &&
        Object.keys(action.payload).length &&
        action.payload.controller === 'layout'
      ) {
        status = action.payload.isCollapsed;
        state.defaultCollapsed = status;
      } else if (typeof action.payload === 'boolean') {
        status = !action.payload ? state.defaultCollapsed : action.payload;
      }
      state.menuCollapsed = status;
      window.localStorage.setItem('menuCollapsed', JSON.stringify(status));
    }
  }
});

export const { handleMenuCollapsed } = sidebarSlice.actions;

export default sidebarSlice.reducer;
