import { authMe } from '../redux/reducers/auth/AuthSlice';
import { store } from '@store/store';

export const AuthMeHelper = () => {
  store.dispatch(authMe());
};

export const getRefreshToken = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  return user?.refreshToken;
}

export const getAccessToken = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  return user?.accessToken;
}

export const updateAccessToken = (token) => {
  let user = JSON.parse(localStorage.getItem("user"));
  user.accessToken = token;
  localStorage.setItem("user", JSON.stringify(user));
}

export const getUser = () => {
  return JSON.parse(localStorage.getItem("user"));
}

export const setUser = (user) => {
  localStorage.setItem("user", JSON.stringify(user));
}

export const removeUser = () => {
  localStorage.removeItem("user");
}
