// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { hanleResponseErrors } from '@helpers/Actions';
import { ls } from '@src/Services/Helpers/LocalStorageEnc';
import axios from 'axios';

export const restUserPassword = createAsyncThunk(
  'resetUserPassword/submit',
  async (
    { new_password, new_password_confirmation, token , domain },
    { fulfillWithValue, rejectWithValue }
  ) => {
    const response = await axios.post(`https://${domain}.fire-maintenance.com/api/user/verify-email`,
    {  
      new_password, 
      new_password_confirmation, 
      token 
    }
    )
      .then((res) => res)
      .catch((e) => hanleResponseErrors(e));
    return response?.status === 200 ? fulfillWithValue(response.data) : rejectWithValue(response.data);
  }
);


export const resetUserPasswordSlice = createSlice({
  name: 'resetUserPassword',
  initialState: {},
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(restUserPassword.fulfilled, (state, action) => {
        localStorage.clear();
        ls.clear();
      })
      .addCase(restUserPassword.rejected, (state, action) => {
        localStorage.removeItem('token');
        ls.clear();
      });
  }
});

export default resetUserPasswordSlice.reducer;
