import baseApi from '@src/API/BaseApi/index';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ls } from '@helpers/LocalStorageEnc';
import { updateUserInfo } from '@userAuth/Login/store';

export const authMe = createAsyncThunk('auth/authMe', async (_, {dispatch}) => {
  const token = ls.getItem('token') ? ls.getItem('token') : null;
   if (token) {
    const response = await baseApi.get('/api/user/me')
    dispatch(updateUserInfo(response.data));
    return response.data;
  }
  throw new Error('Error while getting data');
});

export const AuthSlice = createSlice({
  name: 'authMe',
  initialState: {
    user: {},
    permissions: []
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(authMe.fulfilled, (state, action) => {
      const user = action.payload.user;
      state.permissions = user?.permissions || [];
      state.user = user;
    }).addCase(authMe.rejected, (state, action) => {
      //TODO: must restored
      /* state = {};
      localStorage.clear();
      ls.clear(); */
    })
  }
});

export default AuthSlice.reducer;
