import axios from 'axios';
import { toast } from 'react-toastify';
import { ValuesToFormData } from '@helpers/Actions';
import { getRefreshToken } from '@src/utility/Auth';
import { AUTH_ROUTES, getBaseUrl, getUserToken } from '../helpers';
import { isObject } from 'lodash';

let source = axios.CancelToken.source();

const toastId = toast(<div className="row w-100">
        <div className="row w-100">
          <p className="toast-title fs-4 fw-bold">Form Submission <span id="progress-indicator">(0%)</span></p>
        </div>
        <div className="row w-100 flex-grow-1">
          <small className="text-dark">Please wait till uploading your selected files.</small>
          <small className="text-muted">*uploading progress depends on your internet speed.</small>
        </div>
        <div className="row justify-content-end w-100 flex-grow-1">
          <small 
          className="d-flex justify-content-end text-danger fw-bold cursor-pointer" 
          onClick={() => {
            abortXhrRequest();
          }}
          >Cancel</small>
        </div>
      </div>, {
      autoClose: false,
      closeButton: false,
      closeOnClick: false,
      draggable: false,
      icon: false,
      type: "warning",
      className: "d-none",
      toastId: "form-submittion",
      timeout: 2300
});


let crudApi = axios.create({
  baseURL: getBaseUrl()
});

const abortXhrRequest = () => {
  if (confirm('Are you sure you want to cancel the form submission request?')) {
    window.onbeforeunload = null;
    source.cancel('Request canceled by the user.');
    toast.update(toastId, {
      className: "d-none",
      progress: 0
    });
    setTimeout(() => {
      source = axios.CancelToken.source();
    }, 500);
  }
};

const handleProgressBar = (progressEvent) => {
  try {
    if (progressEvent.loaded === progressEvent.total) {
      setTimeout(() => {
        toast.update(toastId, {
          className: "d-none",
          progress: 100
        });
      }, 800);
      return;
    }

    window.onbeforeunload = () => 'Please wait until the data submitted!';
    const percentage = (progressEvent.loaded * 100 / progressEvent.total);
    toast.update(toastId, {
      className: "d-block",
      progress: percentage / 100
    });
    const indicator = document.getElementById('progress-indicator');
    indicator.innerText = `(${+percentage.toFixed(2)}%)`;
  } catch (error) {
    console.log(error);
  } finally {
    if (progressEvent?.loaded === progressEvent?.total) {
      window.onbeforeunload = null;
    }
  }
};

const onRequest = (config) => {
    const {
      useFormData = false, 
      disableProgress = false, 
      ...payload
    } = {...config.data};
    if (useFormData && isObject(payload)) {
        config.data = ValuesToFormData(payload);
        config.headers['Content-Type'] = 'multipart/form-data';
        if (!disableProgress) {
          config.onUploadProgress = handleProgressBar;
          config.cancelToken = source.token;
        }
    }
    //append user token.
    const token = getUserToken();
    if (token && typeof token === 'string' && token?.length > 0) {
      config.headers.authorization = `Bearer ${token}`;
    }
    return config;
};

const onRequestError = (error) => {
  return Promise.reject(error);
};

const onResponse = (response) => {
  return response;
};

const onResponseError = async (err) => {
  /* const originalConfig = err.config;
  if (!AUTH_ROUTES.includes(originalConfig?.url) && err.response) {
  if (err.response.status === 401 && !originalConfig._retry) {
    originalConfig._retry = true;
    try {
          const rs = await baseApi.post("/api/user/auth/refresh-token", {
            refreshToken: getRefreshToken(),
          });

          const { accessToken } = rs.data;
          //TokenService.updateLocalAccessToken(accessToken);
          return baseApi(originalConfig);

        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }  */
    return Promise.reject(err);
};

crudApi.interceptors.request.use(
  (req) => onRequest(req), 
  (err) => onRequestError(err)
);

crudApi.interceptors.response.use(
  (resp) => onResponse(resp),
  (err) => onResponseError(err)
);

export default crudApi;