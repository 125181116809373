// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { hanleResponseErrors } from '@helpers/Actions';
import { get } from 'lodash';
import baseApi from '@src/API/BaseApi/index';

export const saveUserInfo = createAsyncThunk(
  'registerCompany/saveUserInfo',
  async (params) => params
);
export const saveCompanyInfo = createAsyncThunk(
  'registerCompany/saveCompanyInfo',
  async (params) => params
);
export const saveDatesInfo = createAsyncThunk(
  'registerCompany/saveDatesInfo',
  async (params) => params
);

//save state and post data
export const saveMeetingInfo = createAsyncThunk(
  'salesMeeting/save',
  async (_, { getState, fulfillWithValue, rejectWithValue }) => {
    const state = getState();
    const meeting = state.salesMeeting.meeting;
    return meeting;
    const response = await baseApi
      .post('/api/auth/arrange-sales-meeting', {
        meeting: { ...meeting }
      })
      .then((res) => res)
      .catch((e) => hanleResponseErrors(e));
    return response?.status === 200
      ? fulfillWithValue(response.data)
      : rejectWithValue(response.data);
  }
);

export const salesMeetingSlice = createSlice({
  name: 'salesMeeting',
  initialState: {
    meeting: {
      company: {
        logo: undefined,
        name: undefined,
        site_no: undefined,
        employee_no: undefined,
        sector: undefined,
        country_id: undefined,
        city_id: undefined
      },
      user: {
        first_name: undefined,
        last_name: undefined,
        email: undefined,
        contacts: []
      },
      dates: [],
      verification: {
        codes: {
          email: undefined
        }
      }
    }
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(saveCompanyInfo.fulfilled, (state, action) => {
        state.meeting.company = action.payload.company;
      })
      .addCase(saveCompanyInfo.rejected, (state, action) => {
      
      })
      .addCase(saveUserInfo.fulfilled, (state, action) => {
        state.meeting.user = action.payload.user;
      })
      .addCase(saveUserInfo.rejected, (state, action) => {
      
      })
      .addCase(saveDatesInfo.fulfilled, (state, action) => {
        state.meeting.dates = action.payload.dates;
      })
      .addCase(saveDatesInfo.rejected, (state, action) => {
      
      })
      .addCase(saveMeetingInfo.fulfilled, (state, action) => {
        //state.meeting = {...state.meeting, ...action.payload};
        //state.verification.mobile_number = get(action.payload, "phone_numbers.mobile_number")
      })
      .addCase(saveMeetingInfo.rejected, (state, action) => {
      
      });
  }
});

export default salesMeetingSlice.reducer;
