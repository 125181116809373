import baseApi from '@src/API/BaseApi/index';
import { ls } from '@helpers/LocalStorageEnc';
import axios from 'axios';
//import axios from "axios";
import jsPDF from 'jspdf';
import ReactPDF, { BlobProvider, PDFDownloadLink } from '@react-pdf/renderer';
import * as bufferFrom from 'buffer-from';
import * as qs from 'qs';
import { formikErrorToast } from '@domNotify';
import { Slide, Zoom, Flip, Bounce } from 'react-toastify';
//HINT:please do not delete this Line.
import 'jspdf-autotable';
import { domNotify } from '@components/Notify/DomNotify';
import "./fontsHelper";
import { renderToStaticMarkup, renderToString } from 'react-dom/server';
import ReportTemplate from './PDF/ReportTemplate';
import { render } from 'react-dom';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as moment from 'moment';
import NiceModal from '@ebay/nice-modal-react';
import PeriodicSelector from '@components/PeriodicSelector';
import { Button, Col } from 'reactstrap';

/* export const formikValidator = async (formikProps, singleInstance = true) => {
  return new Promise(async (resolve, reject) => {
    const curErrors = await formikProps.validateForm();
    if (Object.keys(curErrors).length > 0) {
      if (singleInstance) {
        formikErrorToast();
      } else {
        Object.values(curErrors).forEach(value => {
          formikErrorToast();
        });
      }
      reject();
      //throw new Error('errors', curErrors);
    }
    resolve();
  });
} */

export const logout = () => {
  localStorage.clear();
  ls.clear();
  window.location.reload();
}
const flattenErrors = (errorsObj, path = '') => {
  let flattenedErrors = {};

  Object.keys(errorsObj).forEach(key => {
    const errorKey = path ? `${path}.${key}` : key;

    if (typeof errorsObj[key] === 'string') {
      flattenedErrors[errorKey] = errorsObj[key];
    } else if (typeof errorsObj[key] === 'object' && errorsObj[key] !== null) {
      const nestedErrors = flattenErrors(errorsObj[key], errorKey);
      flattenedErrors = { ...flattenedErrors, ...nestedErrors };
    }
  });

  return flattenedErrors;
};

//TODO: handle all messages
export const formikValidator = async ({validateForm}, singleInstance = true, withAlert = true) => {
  const currError = await validateForm();
  const errorMessages = Object.values(flattenErrors(currError));

  if (errorMessages?.length > 0 && withAlert) {
    const message = errorMessages.reduce((prevVal, value) => `${prevVal}<p class="mb-0">${value.toString()}</p>`);
    formikErrorToast(
      message,
      {
        title:"Validation"
      },
      {
        autoClose: 1500,
        transition: Flip,
        hideProgressBar: false,
      }
    );
  }
  
  if (errorMessages?.length > 0) {
    throw new Error('Must Sumbit Valid Data');
  }

};

export const proccessAttachments = async (values, key = 'attachments') => {
  if (isObject(values) && values[key] && Object.keys(values[key]).length) {
    return {
      ...values, 
      [key]: Object.values(values[key]).flat()
    }
  }
  return {...values};
}

export const isFile = input => 'File' in window && input instanceof File;
export const isBlob = input => 'Blob' in window && input instanceof Blob;

// load page by path
export const loadPage = async (page, time = 250) => setTimeout(() => (window.location.href = page), time);

/* export const isEmptyObject = overEvery(isObject, isEmpty);
export const removeEmptyObjects = (obj) => {
    return isArray(obj) ? reject(map(obj, removeEmptyObjects), isEmptyObject) : isObject(obj) ? omitBy(mapValues(obj, removeEmptyObjects), isEmptyObject) : obj; 
}
 */
//import { isFunction, isObject, isEmpty, isArray, isPlainObject, fromPairs } from "lodash-es";

/* export const removeEmptyObjects = (o) => {
    if (isFunction(o) || !isPlainObject(o)) return o;

    if (isArray(o)) return o.map(removeEmptyObjects);

    return fromPairs(
        Object.entries(o)
            .map(([k, v]) => [k, removeEmptyObjects(v)])
            .filter(([k, v]) => !(v == null || (isObject(v) && isEmpty(v))))
    );
}; */
// we just send data
// it mutate object on the place
const isObject = (objValue) => {
  return objValue && typeof objValue === 'object' && objValue.constructor === Object;
}

export const removeEmptyObjects = (o, otherKeys=['lsExpiry']) => {
  otherKeys?.map(key =>{
    if (key) {
      delete o[key];
    }
  })

  for (var k in o) {
    //empty object or empty array
    if ((!Array.isArray(o[k]) && isObject(o[k]) && Object.keys(o[k]).length === 0) || (Array.isArray(o[k]) && o[k].length === 0)) {
      delete o[k];
      continue;
    } else if (Array.isArray(o[k]) && o[k].length > 0) { //filter array values
      o[k] = o[k].filter((e) => e);
      continue;
    } else if (o[k] === null || o[k] === undefined) { //remove null values and undefined values
      delete o[k];
      continue;
    } else if (!o[k] || typeof o[k] !== 'object') {
      continue; // If null or not an object, skip to the next iteration
    }
    // The property is an object
    removeEmptyObjects(o[k]);
  }
};


// important functions for react-query it mutate the object
export const removeUndefined = (object) =>
  Object.keys(object).forEach((k) => object[k] === undefined && delete object[k]);

//TODO: download image
// conver into base64
// return result

/* const loadImage = async (src) => {
  const img = new Image();
  //const base64 = await getBase64(src);
  //img.setAttribute('src', `data:image/png;base64,${base64}`);
  //console.log(img)
  //return base64 ? `data:image/png;base64,${base64}` : undefined;
  return new Promise((resolve, reject) => {
    img.onload = () => resolve(img);
    //img.crossOrigin = "anonymous";
    img.onerror = (error) => {
      return reject(false);
    };
    //img.setAttribute('crossOrigin', 'anonymous');
    img.src = src;
    return img;
  });
  //return img;
}; */


export const getChangedValues = (values, initialValues) => {
  const data = Object.entries(values).reduce((acc, [key, value]) => {
      const hasChanged = initialValues[key] !== value
      if (hasChanged) {
        acc[key] = value
      }
      return acc
    }, {});

    if (Object.keys(data).length < 1) {
      domNotify({
        title:'Error',
        type:'error',
        text:'Please Change Fields Before Submit!'
      })
      throw "Error invalide data";
    }

    return data;
}

function base64ArrayBuffer(arrayBuffer) {
  var base64 = '';
  var encodings = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';

  var bytes = new Uint8Array(arrayBuffer);
  var byteLength = bytes.byteLength;
  var byteRemainder = byteLength % 3;
  var mainLength = byteLength - byteRemainder;

  var a, b, c, d;
  var chunk;

  // Main loop deals with bytes in chunks of 3
  for (var i = 0; i < mainLength; i = i + 3) {
    // Combine the three bytes into a single integer
    chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2];

    // Use bitmasks to extract 6-bit segments from the triplet
    a = (chunk & 16515072) >> 18; // 16515072 = (2^6 - 1) << 18
    b = (chunk & 258048) >> 12; // 258048   = (2^6 - 1) << 12
    c = (chunk & 4032) >> 6; // 4032     = (2^6 - 1) << 6
    d = chunk & 63; // 63       = 2^6 - 1

    // Convert the raw binary segments to the appropriate ASCII encoding
    base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d];
  }

  // Deal with the remaining bytes and padding
  if (byteRemainder == 1) {
    chunk = bytes[mainLength];

    a = (chunk & 252) >> 2; // 252 = (2^6 - 1) << 2

    // Set the 4 least significant bits to zero
    b = (chunk & 3) << 4; // 3   = 2^2 - 1

    base64 += encodings[a] + encodings[b] + '==';
  } else if (byteRemainder == 2) {
    chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1];

    a = (chunk & 64512) >> 10; // 64512 = (2^6 - 1) << 10
    b = (chunk & 1008) >> 4; // 1008  = (2^6 - 1) << 4

    // Set the 2 least significant bits to zero
    c = (chunk & 15) << 2; // 15    = 2^4 - 1

    base64 += encodings[a] + encodings[b] + encodings[c] + '=';
  }

  return base64;
}

/* const makeRequest = async (method, url) => {
  return new Promise(function (resolve, reject) {
      let xhr = new XMLHttpRequest();
      xhr.responseType = 'arraybuffer';
      xhr.onreadystatechange = function() {
          if (this.readyState == 4 && this.status == 200) {
            const base64data = base64ArrayBuffer(xhr.response)
            resolve(base64data);
          } else {
            reject(undefined);
          }
      }
      //xhr.withCredentials = true;
      xhr.open(method, url);//true
      xhr.setRequestHeader( 'Access-Control-Allow-Methods', 'PUT, GET, HEAD, POST, DELETE, OPTIONS');
      xhr.setRequestHeader( 'Access-Control-Allow-Origin', '*');
      xhr.setRequestHeader( "Access-Control-Allow-Headers", "Content-Type, Authorization, X-Requested-With");
      xhr.send(null);
  })
} */

/* const loadImageAsBuffer = async (url) => {
  const base64Image = await axios({
    url: url,
    responseType: 'arraybuffer',
    
  })
    .then((response) => {
      const { data } = response;
      const buffer = Buffer.from(data, 'binary');
      return buffer;
    })
    .then((buffer) => {
      return buffer.toString('base64');
    })
} */
function arrayBufferToBase64(bytes) {
  var binary = '';
  //var bytes = new Uint8Array( buffer );
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

const getBase64 = async (url) => {
  return axios
    .get(url, {
      headers: {
        'Response-Type': 'arraybuffer',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'PUT, GET, HEAD, POST, DELETE, OPTIONS',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization, X-Requested-With'
      }
    })
    .then((response) => {
      //TODO: convert response into base64
      //console.log(isBuffer(response));
      const { data } = response;
      const raw = bufferFrom(data, 'binary').toString('base64'); //bufferFrom(data, 'binary');
      //const imgData = arrayBufferToBase64(bufferImage);
      return 'data:' + response.headers['content-type'] + ';base64,' + raw;
    })
    .catch((err) => false);
};

/* var convertImgToDataURLviaCanvas = (url) => {
  return axios
    .get(url, {
      headers: {
        "Response-Type": "blob",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      },
    })
    .then((response) => {
      var img = new Image();
      let imgUrl = URL.createObjectURL(response.data);
      img.src = imgUrl;
      return img;
    })
    .catch((err) => {
    
      return false;
    });
}; */

//get
export const getRequestHeaders = (isAdmin = false) => {
  const token = ls.getItem('token')?.trim();
  return {
    'Content-type': 'application/json',
    'Authorization': `Bearer ${token}`
  };
};

//conver data to react-select options
export const mapData = (data) =>
  data.length
    ? data.map((e) => {
        return {
          label: e.name,
          value: e.id
        };
      })
    : [];

// ** Converts table to CSV
export const convertArrayOfObjectsToCSV = (array) => {
  let result;
  const columnDelimiter = ',';
  const lineDelimiter = '\n';
  const keys = Object.keys(array[0]);

  result = '';
  result += keys.join(columnDelimiter);
  result += lineDelimiter;

  array.forEach((item) => {
    let ctr = 0;
    keys.forEach((key) => {
      if (ctr > 0) result += columnDelimiter;
      result += item[key];
      ctr++;
    });
    result += lineDelimiter;
  });

  return result;
};

// ** Downloads CSV
export const downloadCSV = (array) => {
  const link = document.createElement('a');
  let csv = convertArrayOfObjectsToCSV(array);
  if (csv === null) return;

  const filename = 'export.csv';

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`;
  }

  link.setAttribute('href', encodeURI(csv));
  link.setAttribute('download', filename);
  link.click();
};

export const donwlodFile = async (url, name) => {
  baseApi.get(`${url}`, {
    responseType: 'blob',
    accept: '*/*',
  })
  .then((response) => {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(response.data);
    link.download = name?.replace(/[^a-zA-Z0-9]/g, '_');
    document.body.append(link);
    link.click();
    link.remove();
    // in case the Blob uses a lot of memory
    setTimeout(() => URL.revokeObjectURL(link.href), 7000);
  }).catch(e => {
    console.log(e)
  });
}


export const exportSystemConfigTables = async (filename, uri, params ={}) => {
  const name = `${filename}_${new Date().toJSON().slice(0,10)}`;
  const filters = filterBuilder(params); //
  const url = `${uri}${filters ? `?${filters}` : ``}`;
  await donwlodFile(url, name);
};
export const exportTable = async (slug, uri, params ={}) => {

};

export const exportFireSystemTables = async (filename, uri, params ={}) => {
  const name = `${filename}_${new Date().toJSON().slice(0,10)}`;
  const filters = filterBuilder(params); //
  const url = `${uri}${filters ? `?${filters}` : ``}`;
  await donwlodFile(url, name);
};


export const ExportSpecificDate = (filename, exportUrl ) => {
  let date=[ undefined , undefined ]
  NiceModal.show('dynamic-modal', {
    title: `Control Panel`,
    prompt: false,
    content: (
      <>
      <PeriodicSelector handleDateChange={ d=> { date=d } } allowedPeriods={["range"]}/>
      <Col className='d-flex justify-content-center mt-5'>
      <Button
      color="success"
      className="formBtnWidth mx-50 mx-sm-1 mb-1 fw-bold"
      type="submit"
      onClick={()=>{
        exportFireSystemTables( filename , exportUrl , { from : date[0] , to : date[1]} )
      }}
      >Submit</Button>
      </Col>
      </>
    )
  })
};

export const spliceIntoChunks = (arr, chunkSize, splitSize = 5) => {
  if (chunkSize < splitSize) return [arr];
  let res = [];
  while (arr.length > 0) {
    const chunk = arr.splice(0, chunkSize);
    res.push(chunk);
  }
  return res;
};

export const convertArrayIntoVariablesWindow = (arr) => {
  for (var i = 0; i < arr.length; i++) {
    window[arr[i]] = i;
  }
};

//TODO: improve it if needed to use
export const convertArrayIntoVariables = (arr) => {
  for (let i = 0; i < arr.length; i++) {
    const key = arr[i];
    const value = arr[key];
    eval('const ' + key + " = '" + value + "'");
  }
};

export const digitsOnly = (value) => /^\d+$/.test(value);

export const hanleResponseErrors = (e) => {
  if (e.response) {
    return e.response;
  } else if (e.request) {
    return e.request;
  } else if (e.message) {
    return e.message;
  }
  return e;
};

export const attachementArrayBuilder = (files, type) => {
  let arr = [];
  files.length ? files.map((file_path) => arr.push({ type, attachment_url: file_path })) : void 0;
  return arr;
};

export const phoneHider = (phone) => {
  let chars = phone ? phone.split('') : [];
  for (let i = 0; i < chars.length - 3; i++) {
    chars[i] = '*';
  }
  return chars.join('');
};

function isArabic(text) {
  var pattern = /[\u0600-\u06FF\u0750-\u077F]/;
  result = pattern.test(text);
  return result;
}

export const convertToPdf11 = async (
  data = null, 
  header = null, 
  filename = 'exported-data.pdf',
  fileSubject,
  fileAuthor,
  fileCreator
) => {
const doc = new jsPDF({
	orientation: 'portrait',
	format: 'a4',
  //filters: ['ASCIIHexEncode']
});
doc.setFont("Cairo-VariableFont_slnt,wght");
doc.text(doc.processArabic("النص العربي"), 10, 10);
//doc.setFont("Times");
doc.text(doc.processArabic("English text?"), 10,50);
doc.save('pdf_name');
return;
//let html = renderToStaticMarkup(<ReportTemplate />);
//console.log(html);
doc.html(`<h1>مرحبا بالعربي</h1>`,  {
	async callback(doc) {
    //console.log("done")
	},
});
return;
doc.setProperties({
  title: filename,
  subject: fileSubject ?? 'Subject',
  author: fileAuthor ?? 'IGS-Company',
  creator: fileCreator ?? 'IGS-Company',
});

if( window?.navigator?.mimeTypes && window?.navigator?.mimeTypes['application/pdf'] !== undefined ) {
  window.open(
    doc.output("bloburl", {dataurlnewwindow: `${filename}`}), 
    "_blank"
  );
  return;
}

};


export const convertToPdf = async (
    data = null, 
    header = null, 
    filename = 'exported-data.pdf',
    fileSubject,
    fileAuthor,
    fileCreator
  ) => {
    return <BlobProvider
    document={<>hello chems</>} >
           {({ url }) => (
             <a
               href={url}
               target="_blank"
               rel="noreferrer noopener"
             >
               <b>Go to PDF</b>
             </a>
           )}
    </BlobProvider>
    //render(<>hello</>);
//ReactPDF.renderToStream(<ReportTemplate />);
//ReactPDF.render(<ReportTemplate />, `${__dirname}/example.pdf`);
   /*  const PDF_FONT = "Vazirmatn";
  const doc = new jsPDF({
    format: "a4",
    unit: "px"
  });
  
let html = renderToString( <ReportTemplate /> );
//console.log(html);
doc.html(html, {
	async callback(doc) {
    await doc.setFont(PDF_FONT, "normal");
    console.log("done", doc.getFontList());
    await doc.save('hello.pdf')
	},
}); */
return;
  var pageSize = doc.internal.pageSize;
  var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
  let pdfObject = {
    image: {
      height: 25,
      width: 25,
      left: 14,
      top: 5
    },
    table: {
      marginLeft: 14,
      marginRight: 14
    },
    header: {
      title: {
        left: (pageWidth - 39) / 2,
        top: 22
      },
      image: {
        right: {
          height: 0,
          width: 25,
          left: pageWidth - 39,
          top: 5
        },
        left: { height: 0, width: 25, left: 14, top: 5 }
      },
      end: 30
    }
  };

  doc.setFont(PDF_FONT);
  
  if (header) {
    let { imageLeft, imageRight } = header;
    if (imageLeft) {
      doc.addImage(
        imageLeft,
        'PNG',
        pdfObject.header.image.left.left,
        pdfObject.header.image.left.top,
        pdfObject.header.image.left.width,
        pdfObject.header.image.left.height
      );
      /* await getBase64(`${imageLeft}`).then(res => {
        let imgLeft = new Image();
        imgLeft.src = imageLeft;
        doc.addImage(
          imgLeft,
          "PNG",
          pdfObject.header.image.left.left,
          pdfObject.header.image.left.top,
          pdfObject.header.image.left.width,
          pdfObject.header.image.left.height
        );
      }); */
    }

    if (imageRight) {
      doc.addImage(
        imageRight,
        'PNG',
        pdfObject.header.image.right.left,
        pdfObject.header.image.right.top,
        pdfObject.header.image.right.width,
        pdfObject.header.image.right.height
      );
      /* await getBase64(`${imageRight}`)
      .then(res => {
        let imgRight = new Image();
        imgRight.src = imageRight;
        doc.addImage(
          imgRight,
          "PNG",
          pdfObject.header.image.right.left,
          pdfObject.header.image.right.top,
          pdfObject.header.image.right.width,
          pdfObject.header.image.right.height
        );
      }); //await loadImage(); */
    }

    //var width = doc?.getTextWidth(header?.title);
    doc.text(header.title, pdfObject.header.title.left - 8, pdfObject.header.title.top);
  }

  if (data) {
    let lastFull = false;
    let lastLeft = false;
    let lastLeftPosition = 0;
    let totalAddedTop = 13;
    var finalY = doc?.lastAutoTable?.finalY || 10;
    for (var key in data) {
      doc.setFontSize(10);
      if (data[key].childTitle) {
        doc.text(
          doc.processArabic(data[key].childTitle),
          pdfObject.table.marginLeft,
          parseInt(key) === 0 ? pdfObject.header.end + 15 : lastLeftPosition + 5
        );
      }
      if (data[key].title) {
        doc.setFontSize(10);
        doc.text(
          doc.processArabic(data[key].title),
          (pageWidth - (pdfObject.table.marginLeft + pdfObject.table.marginRight)) / 2,
          parseInt(key) === 0 ? pdfObject.header.end + 10 : lastLeftPosition
        );

        if (data[key].showDivider) {
          var width = doc.getTextWidth(data[key].title);
          doc.setDrawColor(128, 128, 128); // draw red lines

          doc.setLineWidth(0.3);
          doc.line(
            pdfObject.table.marginLeft,
            parseInt(key) === 0 ? pdfObject.header.end + 8 : lastLeftPosition - 1.5,
            (pageWidth - (pdfObject.table.marginLeft + pdfObject.table.marginRight)) / 2 - 3,
            parseInt(key) === 0 ? pdfObject.header.end + 8 : lastLeftPosition - 1.5
          ); // vertical line
          doc.line(
            (pageWidth - (pdfObject.table.marginLeft + pdfObject.table.marginRight)) / 2 +
              width +
              3,
            parseInt(key) === 0 ? pdfObject.header.end + 8 : lastLeftPosition - 1.5,
            pageWidth - pdfObject.table.marginRight,
            parseInt(key) === 0 ? pdfObject.header.end + 8 : lastLeftPosition - 1.5
          );
        }
      }
      if (data[key].type === 'table') {
        // draw a line for nested table header
        if (data[key].nested) {
          data[key].contents.forEach((ele, index) => {
            finalY = doc.lastAutoTable.finalY + 2 || 10 + totalAddedTop;
            doc.autoTable({
              theme: 'plain',
              startY:
                parseInt(key) === 0 && index === 0
                  ? pdfObject.header.end + 10 + totalAddedTop
                  : lastLeft && !ele.full
                  ? index === 0
                    ? lastLeftPosition + totalAddedTop
                    : lastLeftPosition
                  : index === 0
                  ? finalY + totalAddedTop
                  : finalY,
              head: [[doc.processArabic(ele.title)]],
              styles: { 
                ...data[key].headStyle,
                overflow: 'linebreak',
                font: PDF_FONT,
                fontStyle: 'normal',
              },
            });
            finalY = doc.lastAutoTable.finalY || 10;
            doc.autoTable({
              theme: 'plain',
              startY: finalY,
              head: ele.head,
              body: ele.body,
              styles: { 
                ...data[key].style,
                overflow: 'linebreak',
                font: PDF_FONT,
                fontStyle: 'normal',
              },
              // columnStyles: {
              //   0: { cellWidth: 30 },
              //   1: { cellWidth: 30 },
              //   2: { cellWidth: 30 },
              //   // etc
              // },
              columnStyles: (() => {
                let k = {};
                ele.body.forEach((ele, index) => {
                  k[index] = {
                    cellWidth:
                      (pageWidth - (pdfObject.table.marginLeft + pdfObject.table.marginRight)) / 4
                  };
                });
                return k;
              })()
            });
            lastLeftPosition =
              index === data[key].contents.length - 1
                ? doc.lastAutoTable.finalY + 8
                : doc.lastAutoTable.finalY;
          });
        } else {
          data[key].contents.forEach((ele, index) => {
            finalY = doc.lastAutoTable.finalY + 2 || 10 + totalAddedTop;
            doc.autoTable({
              startY:
                parseInt(key) === 0 && index === 0
                  ? pdfObject.header.end + 10 + totalAddedTop
                  : lastLeft && !ele.full
                  ? index === 0
                    ? lastLeftPosition + totalAddedTop
                    : lastLeftPosition
                  : index === 0
                  ? finalY + totalAddedTop
                  : finalY,
              head: ele.head,
              body: ele.body,
              styles: { 
                ...data[key].style,
                overflow: 'linebreak',
                font: PDF_FONT,
                fontStyle: 'normal',
              },
            });
            lastLeftPosition = index === data[key].contents.length - 1 ? doc.lastAutoTable.finalY + 8 : finalY;
          });
        }
      }
      if (data[key].type === 'content') {
        data[key].contents.forEach((ele, index) => {
          finalY = doc?.lastAutoTable?.finalY + 2 || 10 + totalAddedTop;
          doc.autoTable({
            //theme: 'grid',
            startY:
              parseInt(key) === 0 && index === 0
                ? pdfObject.header.end + 10 + (totalAddedTop - 7)
                : lastLeft && !ele.full
                ? index === 0
                  ? lastLeftPosition + totalAddedTop
                  : lastLeftPosition
                : index === 0
                ? finalY + totalAddedTop
                : finalY,

            columnStyles: {
              0: {
                cellWidth:
                  (pageWidth - (pdfObject.table.marginLeft + pdfObject.table.marginRight)) / 2 / 3 +
                  5,
                ...data[key].style
              }
            },
            body: [[ele.label, ele.value]],
            styles: { 
              overflow: 'linebreak',
              font: PDF_FONT,
              fontStyle: 'normal',
            },
            margin: {
              right: ele.full
                ? pdfObject.table.marginRight
                : lastLeft
                ? pdfObject.table.marginRight
                : (pageWidth - (pdfObject.table.marginLeft + pdfObject.table.marginRight)) / 2 + 15,
              left:
                lastLeft && !ele.full
                  ? (pageWidth - (pdfObject.table.marginLeft + pdfObject.table.marginRight)) / 2 +
                    15
                  : pdfObject.table.marginLeft
            }
          });
          lastFull = ele.full ? true : false;
          lastLeft = ele.full ? false : lastLeft ? false : true;

          lastLeftPosition =
            index === data[key].contents.length - 1 ? doc.lastAutoTable.finalY + 8 : finalY;
        });
      }
    }

    doc.setProperties({
      title: filename,
      subject: fileSubject ?? 'Subject',
      author: fileAuthor ?? 'IGS-Company',
      creator: fileCreator ?? 'IGS-Company',
    });

    if( window?.navigator?.mimeTypes && window?.navigator?.mimeTypes['application/pdf'] !== undefined ) {
      window.open(
        doc.output("bloburl", {dataurlnewwindow: `${filename}`}), 
        "_blank"
      );
      return;
    } 
    doc.save(`${filename}`);
  }
};

export const filterBuilder = (params, useQueryString = true) => {
  let result = {};
  try {
    const { filters, ...restParams } = params;
    for (let key in filters) {
      if (key && key.indexOf(';') !== -1) {
        key.split(';').map((_k) => {
          if (_k && _k.length) {
            result[_k] = filters[key];
          }
        });
      } else {
        result[key] = filters[key];
      }
    }
    if (useQueryString) {
      return qs.stringify({
        ...restParams,
        filter: result
      });
    }
  } catch (error) {}
  return result;
};

export const mapFilters = (values) => {
  let result = [];
  try {
    let filters = [];
    if (values && values.length > 0) {
      values.map((filter) => {
        if (filter.id && filter.id.indexOf(';') > -1) {
          let d = filter.id.split(';');
          d.map((e) => filters.push({[e]: filter.value}));
        } else {
          filters.push({[filter.id]: filter.value});
        }
      });
    }
    result = filters.reduce((acc, f) => {
      acc[Object.keys(f)[0]] = f[Object.keys(f)[0]];
      return acc;
    }, {});
  } catch (error) {}
  return result;
}; 

/* export const filterBuilder = (filters) => {
  let query = new Query();
  for (let key in filters) {
    if (key && key.indexOf(';') !== -1) {
      key.split(';').map((_k) => {
        if (_k && _k.length) {
          query = query.where(_k, filters[key]);
        }
      });
    } else {
      query = query.where(key, filters[key]);
    }
  }
  return query;
}; */


export const ConvertTime=(Time)=>{
  let Point=Time.indexOf(":")
  let Hours=+Time.slice(Point-2,Point)
  let Minutes=+Time.slice(Point+1,Point+3)
  if(Hours>12 && Hours<=21){
      if(Minutes<10){
          return (`0${Hours-12}:0${Minutes} PM`)
      }else{
          return (`0${Hours-12}:${Minutes} PM`)
      }
  }
  else if(Hours>=22 && Hours<=24){
      if(Minutes<10){
          return (`${Hours-12}:0${Minutes} PM`)
      }else{
          return (`${Hours-12}:${Minutes} PM`)
      }
  }
  else if(Hours>=0 &&Hours<=9){
      if(Minutes<10){
          return (`0${Hours}:0${Minutes} AM`)
      }else{
          return (`0${Hours}:${Minutes} AM`)
      }
  }
  else if(Hours<12 &&Hours>=10){
      if(Minutes<10){
          return (`${Hours}:0${Minutes} AM`)
      }else{
          return (`${Hours}:${Minutes} AM`)
      }
  }
  else if(Hours===12){
      if(Minutes<10){
          return (`${Hours}:0${Minutes} PM`)
      }else{
          return (`${Hours}:${Minutes} PM`)
      }
  }
  else if(Hours===24 &&Hours>=10){
      if(Minutes<10){
          return (`${Hours-24}:0${Minutes} PM`)
      }else{
          return (`${Hours-24}:${Minutes} PM`)
      }
  }
  
}

export const getProps = async(row) => {
  await new Promise(resolve => setTimeout(resolve, 750));
  return row;
}

export const CheckUserLogin=()=>{
  const history = useHistory();
  useSelector((state) => {
    if((state?.loginUser?.isLogin)){
      history.push("/dashboard");
    return <></>;
    }
  })
}

// export const SetFireListName=(formikProps ,prefix = undefined )=>{
//   const type = `${formikProps?.values?.type}`;
//   const number = formikProps?.values?.number;
//   const size = formikProps?.values?.SizeName;
//   const mainArea = `${formikProps?.values?.MainAreaName ?? formikProps?.values?.fireArea?.parent?.name}`;
//   const subArea = `${formikProps?.values?.AreaIdName ?? formikProps?.values?.fireArea?.name}`;
//   const floorName = formikProps?.values?.floor;
//   const filtred = [prefix, number, type, size, mainArea, subArea, floorName].filter(w => w !== "undefined" && w !== undefined);
//   const name = filtred.join('-').replace(/\-$/, "").replace(/^\-/, "");//join words and replace first and last - if exists
//   formikProps.setFieldValue("name", name);
// }
// export const SetFireListName=(formikProps ,prefix = undefined )=>{
//   const type = `${formikProps?.values?.type}`;
//   const number = formikProps?.values?.number;
//   const size = formikProps?.values?.SizeName;
//   const mainArea = "%M";
//   const subArea = "%S";
//   const floorName = formikProps?.values?.floor;
//   const filtred = [prefix, number, type, size, mainArea, subArea, floorName].filter(w => w !== "undefined" && w !== undefined);
//   const name = filtred.join('-').replace(/\-$/, "").replace(/^\-/, "");//join words and replace first and last - if exists
//   formikProps.setFieldValue("name", name);
// }

export function convertToFormData(obj, formData = new FormData(), parentKey = '') {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      const propName = parentKey ? `${parentKey}[${key}]` : key;

      if (value === null || value === undefined) {
        formData.append(propName, '');
      } else if (typeof value === 'object' && !(value instanceof File) && !(value instanceof Date)) {
        convertToFormData(value, formData, propName);
      } else if (Array.isArray(value)) {
        value.forEach((item, index) => {
          const arrayKey = `${propName}[${index}]`;
          appendFormData(formData, arrayKey, item);
        });
      } else {
        appendFormData(formData, propName, value);
      }
    }
  }

  return formData;
}

function appendFormData(formData, key, value) {
  if (value instanceof File) {
    formData.append(key, value);
  } else if (value instanceof Date) {
    formData.append(key, value.toISOString());
  } else if (typeof value === 'boolean') {
    formData.append(key, value ? 'true' : 'false');
  } else {
    formData.append(key, value.toString());
  }
}

export const getRandomKey = () => Math.random().toString(36).substring(7);

export const ValuesToFormData = (values) => {
  const formData = new FormData();  
  Object.keys(values).forEach(key => {
    if (Array.isArray(values[key])) {
      values[key].map((ele,index)=>{
          if(ele?.path===undefined){
            if(typeof ele === "object"){
              Object.keys(ele).map((e)=>{
                if(!(typeof ele[e] ==="object")){
                  formData.append(`${key}[${index}][${e}]`,ele[e])
                }
              })
            }else {
              formData.append(`${key}[${index}]`,ele)
            }
          }else if(ele?.path?.id !==undefined){
            formData.append(`${key}[${index}]`,ele?.path?.url)
          }
          else {
            if(ele?.type === "picture"){
              formData.append(`${key}`,ele?.path)
            }else{
              formData.append(`${key}[${index}]`,ele?.path)
            }
          }
        })
      } else if(values[key] instanceof File) {
        formData.append(key, values[key]);
      }
      else if(typeof values[key] === "object"){
        Object.keys(values[key]).map((e)=>{
          formData.append(`${key}[${e}]`,values[key][e])
        })
      }
      else {
      formData.append(key, values[key]);  
    }   
  });
  return formData;
}

const titleCase = (str) => {
  // Convert the string to lowercase and split it into an array of words
  let words = str?.toLowerCase().split(' ') || [];

  // Iterate through each word
  for (let i = 0; i < words.length; i++) {
    // Capitalize the first letter of the word and concatenate it with the rest of the word
    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
  }

  // Join the words back into a single string
  let titleCaseStr = words.join(' ');

  return titleCaseStr;
}

export const humanText = (text) => (text && text?.length ? titleCase(text?.replaceAll(/[^a-zA-Z0-9]+/g, ' ')) : '')


export const getFloorNames=()=> {
  const floorNames = ["Ground", "1st", "2nd", "3rd"];
  const allFloors = [];
  for (let floor = 0; floor <= 30; floor++) {
    const floorName = floor < floorNames.length ? floorNames[floor] : floor + "th";
    allFloors.push({
      value: floorName + ' Floor',
      label: floorName + ' Floor',
      name: floorName + ' Floor'
    });
  }
  return allFloors;
}

// function calculateTimeDifference(Time) {
//   const currentTime = moment(new Date());
//     const timeDifferenceInSeconds = currentTime.diff(Time, 'seconds');
//   return timeDifferenceInSeconds/60;
// }

// export const checkInputNumber=(e)=>{
//   const inputValue = e.target.value;
  
//   if (!/^[0-9]+$/.test(inputValue)) {
//     e.target.value = inputValue.replace(/[^\d]/g, "");
//   }
//   if (/^(?!(00))[0-9]+$/.test(inputValue)) {
//     e.target.value = inputValue.replace(/^0{2,}/, "0");
//   }
// }