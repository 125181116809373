import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initialState = {
  show: false,
  type: 'info',
  title: undefined,
  smallDesc: undefined,
  text: undefined,
  additional: {},
  callbacks: []
};

/* export const formikErrorToast = createAsyncThunk(
  'toastify/formikToast',
  async (message, { dispatch }) => {
    dispatch(
      showToast({
        type: 'error',
        title: 'Error',
        text: message || 'Error, Please Fill All Required Fields Correctly!'
      })
    );
  }
); */

export const toastifySlice = createSlice({
  name: 'toastify',
  initialState,
  reducers: {
    showToast: (state, action) => {
      return {
        ...state,
        ...action.payload,
        show: true
      };
    },
    resetToast: (state, action) => {
      return {
        ...state,
        ...initialState,
        show: false
      };
    }
  }
});

export const { showToast, resetToast } = toastifySlice.actions;

export default toastifySlice.reducer;
